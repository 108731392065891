import React from 'react';

type Props = {
  size: number | string;
  color: string;
};

const Exit = ({ size, color }: Props) => {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
      <path d="M12 10v-2h-5v-2h5v-2l3 3zM11 9v4h-5v3l-6-3v-13h11v5h-1v-4h-8l4 2v9h4v-3z" />
    </svg>
  );
};

Exit.defaultProps = {
  size: 16,
  color: '#000',
};

export default Exit;
